<template>
  <div class="home-footer">
    <div class="c-flex c-row-center">
      <!-- <p>关于我们</p> -->
      <!-- 公众号 -->
      <Poptip trigger="click">
        <p class="c-cursor-pointer c-color-gary2">{{ $constant.name }}公众号</p>
        <template #content>
          <div class="c-text-center">
            <p class="">关注{{ $constant.name }}</p>
            <div class="c-flex">
              <img
                class="cjw-image-qrcode"
                :src="$constant.qrcodeTencent"
                alt="qrcode"
              />
            </div>
            <p class="c-font-xs c-font-color-minor">请用微信扫码关注</p>
          </div>
        </template>
      </Poptip>
      <!-- <p>加入我们</p> -->
    </div>
    <div>意见反馈：{{ $constant.companyEmail }}</div>
    <div>地址：{{ $constant.companyAddress }}</div>
    <div class="c-flex c-row-center">
      <p class="c-margin-right-15">{{ $constant.companyName }}</p>
      <p
        class="c-margin-right-15 c-cursor-pointer c-color-gary2"
        @click="toICPSearch"
      >
        ICP认证：{{ $constant.ICPNumber }}
      </p>
      <p class="c-margin-right-15">
        {{ $constant.copyrightInfo }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    const toICPSearch = function () {
      window.open('https://beian.miit.gov.cn')
    }
    return {
      toICPSearch
    }
  }
}
</script>
<style scoped lang="scss">
.home-footer {
  padding: 20px;
  min-width: 1280px;
  color: #c5c8ce;
  line-height: 1.8;
  text-align: center;
  .cjw-image-qrcode {
    width: 120px;
  }

  .c-color-gary2 {
    color: #f7f7f7;
  }
}
</style>
