<template>
  <div class="home-slogan">
    <p>英语词汇</p>
    <p>智慧学习系统</p>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
.home-slogan {
  color: #fff4f4;
  font-size: 48px;
  font-weight: 900;
}
</style>
