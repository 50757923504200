<template>
  <div class="home-content-show c-flex" v-if="contentInfo">
    <div class="c-flex-1 c-text-center" v-if="imageDirection === 'left'">
      <img class="cjw-image" :src="contentInfo.imageUrl" alt="contentShow" />
    </div>
    <div class="c-flex-1 c-flex c-row-center">
      <div class="cjw-text">
        <h4 class="cjw-text-title c-margin-bottom-20 c-font-color-main">
          {{ contentInfo.title }}
        </h4>
        <p
          class="cjw-text-li c-margin-bottom-10 c-font-md c-font-color-routine"
          v-for="(item, index) in contentInfo.list"
          :key="index"
        >
          <Icon type="md-water" />
          <span class="c-margin-left-5">{{ item }} </span>
        </p>
      </div>
    </div>
    <div class="c-flex-1 c-text-center" v-if="imageDirection === 'right'">
      <img class="cjw-image" :src="contentInfo.imageUrl" alt="contentShow" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contentInfo: {
      type: Object,
      defaulte: function () {
        return null
      }
    },
    imageDirection: {
      type: String,
      defaulte: 'right'
    }
  }
}
</script>
<style scoped lang="scss">
.home-content-show {
  padding: 20px 0;
  .cjw-text {
    width: 380px;
    .cjw-text-title {
      font-size: 28px;
    }
  }
  .cjw-image {
    width: 450px;
  }
}
</style>
