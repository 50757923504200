<template>
  <div class="home">
    <div class="cjw-header c-flex c-row-center">
      <home-header></home-header>
    </div>
    <div class="cjw-section c-flex c-row-center">
      <home-section></home-section>
    </div>
    <div class="cjw-footer c-flex c-row-center c-margin-top-40">
      <home-footer></home-footer>
    </div>
  </div>
</template>

<script>
import HomeHeader from './home-header.vue'
import HomeSection from './home-section.vue'
import HomeFooter from './home-footer.vue'

import { onMounted, getCurrentInstance } from 'vue'

export default {
  name: 'HomeView',
  components: {
    HomeHeader,
    HomeSection,
    HomeFooter
  },
  setup() {
    const title =
      getCurrentInstance()?.appContext.config.globalProperties.$constant.name
    onMounted(() => {
      document.title = `${title}-英语词汇智慧学习系统`
    })
    return {}
  }
}
</script>

<style scoped lang="scss">
.cjw-header {
  background: linear-gradient(to left, #b5f0cf, #00c355 60%);
}
.cjw-footer {
  border-top: 1px solid #2e364d;
  background-color: #2e364d;
}
</style>
