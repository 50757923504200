// const envPacking = 'test'
const envPacking = 'official'

// const version = '1.0.1.20230208'

import logoTest from '@/assets/logo_test.jpg'
import logoOfficial from '@/assets/logo_official.png'
import qrcodeTencentOfficial from '@/assets/qrcode_tencent_official.png'
import qrcodeTencentTest from '@/assets/qrcode_tencent_test.png'
import qrcodeVocabularyOfficial from '@/assets/qrcode_vocabulary_official.png'
import qrcodeVocabularyTest from '@/assets/qrcode_vocabulary_test.png'

const constant = {
  name: envPacking === 'official' ? '词达人' : '词掌门',
  ICPNumber:
    envPacking === 'official' ? '湘ICP备17005069号-2' : '湘ICP备17005069号-2',
  logo: envPacking === 'official' ? logoOfficial : logoTest,
  qrcodeTencent:
    envPacking === 'official' ? qrcodeTencentOfficial : qrcodeTencentTest,
  qrcodeVocabulary:
    envPacking === 'official' ? qrcodeVocabularyOfficial : qrcodeVocabularyTest,
  companyName: '湖南才田网络科技有限公司',
  companyAddress:
    '中国（湖南）自由贸易试验区长沙芙蓉片区隆平高科技园合平路618号A座3楼301-1室',
  companyEmail: 'vocabgo_app@163.com',
  copyrightInfo: 'Copyright © 2023 才田 All Rights Reserved.'
  // 1. 符号© 2. 作品发表年份，或一个期间 3. 作者或是版权拥有者的名称或缩写
}

export default constant
