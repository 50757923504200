<template>
  <div class="c-viewpager">
    <img
      class="cjw-viewpager-image-main"
      src="@/assets/viwepager_002.jpg"
      alt="picture"
    />
    <img
      class="cjw-viewpager-image-left"
      src="@/assets/viwepager_003.jpg"
      alt="picture"
    />
    <img
      class="cjw-viewpager-image-right"
      src="@/assets/viewpager_004.jpg"
      alt="picture"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      theme: 'light'
    }
  }
}
</script>
<style scoped lang="scss">
.c-viewpager {
  position: relative;
  width: 660px;
  height: 498px;
  overflow: hidden;
  .cjw-viewpager-image-main {
    width: 100%;
    height: 100%;
    width: 280px;
    height: 498px;
    position: absolute;
    z-index: 99;
    left: 180px;
    border-radius: 12px;
    box-shadow: 0 0 10px 0 #0bc75d;
  }
  .cjw-viewpager-image-left,
  .cjw-viewpager-image-right {
    width: 240px;
    height: 427px;
    border-radius: 12px;
    position: absolute;
    top: 34px;
    opacity: 0.9;
    z-index: 9;
    box-shadow: 0 0 10px 0 #0bc75d;
  }

  .cjw-viewpager-image-left {
    left: 0;
  }
  .cjw-viewpager-image-right {
    right: 0;
  }
}
</style>
