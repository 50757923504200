<template>
  <div class="home-section">
    <p class="cjw-preamble">
      {{
        $constant.name
      }}英语词汇智慧学习系统是以微信公众号为入口、基于语料库的手机词汇教学解决方案。{{
        $constant.name
      }}结合教材内容与应试需求，倡导“以练促学”的词汇深度学习方法，引入教学管理和教师监督机制，帮助教师将词汇教学更好地融入大学英语教学体系。
    </p>
    <!-- <div class="c-flex c-margin-top-50">
      <div class="c-flex-1">
        <div class="c-text-center"><Button type="success">教师端</Button></div>
      </div>
      <div class="c-flex-1">
        <div class="c-text-center"><Button type="success">学生端</Button></div>
      </div>
      <div class="c-flex-1">
        <div class="c-text-center"><Button type="success">管理端</Button></div>
      </div>
    </div> -->
    <div></div>
    <home-section-teacher></home-section-teacher>
  </div>
</template>
<script>
import HomeSectionTeacher from './home-section-teacher.vue'
export default {
  components: {
    HomeSectionTeacher
  }
}
</script>
<style scoped lang="scss">
.home-section {
  min-width: 1180px;
  .cjw-preamble {
    margin-top: 50px;
    padding: 0 10%;
    font-size: 18px;
    line-height: 1.6;
    text-indent: 2em;
  }
}
</style>
