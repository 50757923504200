<template>
  <div class="home-company-info c-flex">
    <!-- logo 图标 -->
    <div class="cjw-logo c-flex">
      <img class="cjw-logo-vocabgo" :src="$constant.logo" alt="logo_vocabgo" />
      <p>{{ $constant.name }}</p>
    </div>
    <div class="c-flex-1 c-row-right c-col-center c-flex">
      <div class="c-margin-right-16">
        <!-- 词汇量测试 -->
        <Poptip trigger="hover">
          <Button type="success">词汇量测试</Button>
          <template #content>
            <div class="c-text-center">
              <p class="">关注{{ $constant.name }}</p>
              <div class="c-flex">
                <img
                  class="cjw-image-qrcode"
                  :src="$constant.qrcodeVocabulary"
                  alt="qrcode"
                />
              </div>
              <p class="c-font-xs c-font-color-minor">请用微信扫码测试</p>
            </div>
          </template>
        </Poptip>
      </div>
      <!-- 公众号 -->
      <Poptip trigger="hover">
        <Button type="success">{{ $constant.name }}公众号</Button>
        <template #content>
          <div class="c-text-center">
            <p class="">关注{{ $constant.name }}</p>
            <div class="c-flex">
              <img
                class="cjw-image-qrcode"
                :src="$constant.qrcodeTencent"
                alt="qrcode"
              />
            </div>
            <p class="c-font-xs c-font-color-minor">请用微信扫码关注</p>
          </div>
        </template>
      </Poptip>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
.home-company-info {
  .cjw-logo {
    height: 48px;
    color: #fff;
    font-size: 24px;
    // background-color: #b5f0cf;
    .cjw-logo-vocabgo {
      margin-right: 10px;
      height: 40px;
      border-radius: 4px;
    }
  }
}

.cjw-image-qrcode {
  width: 120px;
  height: 120px;
}
</style>
