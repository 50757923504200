import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './store'

const app = createApp(App)

// viewuiplus
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'

// 常用样式
import '@/libs/scss/index.scss'

// 常量
import constant from '@/libs/js/constant.js'
app.config.globalProperties.$constant = constant

app.use(router).use(pinia).use(ViewUIPlus).mount('#app')
