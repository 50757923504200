<template>
  <div class="home-teacher-content">
    <div
      class="c-margin-top-50"
      v-for="(contentInfo, index) in contentInfoList"
      :key="index"
    >
      <home-content-show
        :content-info="contentInfo"
        :image-direction="index % 2 === 0 ? 'right' : 'left'"
      ></home-content-show>
    </div>
  </div>
</template>
<script>
import HomeContentShow from './home-content-show.vue'

import image1 from '@/assets/teacher_task_info.png'
import image2 from '@/assets/teacher_task_setting.jpg'
import image3 from '@/assets/teacher_task_type.jpg'

export default {
  components: {
    HomeContentShow
  },
  setup() {
    const contentInfoList = [
      {
        imageUrl: image1,
        title: '强大的教学管理功能',
        list: [
          '教师可通过布置练习、测试、PK任务，有效驱动词汇教学流程，轻松掌握所有班级学生的学习情况，并可将学习表现纳入成绩评估体系',
          '院系负责人可通过管理端直观了解全校的学情数据'
        ]
      },
      {
        imageUrl: image2,
        title: '词汇深度学习',
        list: [
          '提供看词选义、看义选词、听音选义、选词搭配、选词补句等近20种题型。',
          '帮助学生掌握词汇在不同语境中的意义，实现从词汇到语法、篇章、阅读的跨越，提升四级通过率'
        ]
      },

      {
        imageUrl: image3,
        title: '多类型任务适用不同教学场景',
        list: [
          '自学、练习、测试和PK任务适用于课前预习、课后复习、阶段性测试等场景。',
          '支持以班级为单位，组织灵活的词汇课堂小测验。',
          '支持以学校、年级为单位，组织指定词表范围的词汇竞赛'
        ]
      }
    ]
    return {
      contentInfoList
    }
  }
}
</script>
<style scoped lang="scss"></style>
