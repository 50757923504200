<template>
  <div class="home-header">
    <div class="cjw-home-company-info">
      <home-company-info />
    </div>
    <div class="c-flex c-margin-top-50 c-margin-bottom-30 c-padding-top-10">
      <div class="c-flex-1 c-flex c-row-center">
        <home-content-swipe />
      </div>
      <div class="c-flex-1">
        <div class="cjw-home-slogan">
          <home-slogan />
        </div>
        <!-- <div class="cjw-home-app-download">
          <home-app-download />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import HomeCompanyInfo from './home-company-info.vue'
import HomeContentSwipe from './home-content-swipe.vue'
import HomeSlogan from './home-slogan.vue'
// import HomeAppDownload from './home-app-download.vue'

export default {
  components: {
    HomeCompanyInfo,
    HomeContentSwipe,
    HomeSlogan
    // HomeAppDownload
  }
}
</script>
<style scoped lang="scss">
.home-header {
  padding: 25px 50px;
  min-width: 1280px;
  .cjw-home-slogan,
  .cjw-home-app-download {
    margin-left: 150px;
  }
  .cjw-home-slogan {
    margin-top: 100px;
    margin-bottom: 120px;
  }
}
</style>
